import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MobileHeader.css";
import menu_icon from "../../assets/icons/menu.png";
import close_icon from "../../assets/icons/close.png";
import AccordingMenu from "./AccordingMenu";
import {
  companyMenu,
  industriesMenu,
  productMenu,
  servicesMenu,
} from "../../data/menuData";

const MobileHeader = ({ caseStudyMenu }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <div className="mobile_header bg-white">
        <div className="mobile_header_content">
          <Link to="/">
            <h1>
              iOTAS
              <sup className="hjhjhjh">
                <small>TM</small>
              </sup>
              <br />
              <small>Solutions Pvt. Ltd.</small>
            </h1>
          </Link>

          <img
            onClick={() => setShowDrawer(true)}
            src={menu_icon}
            alt="menu_icon"
          />
        </div>

        <div className={showDrawer ? "showDrawer open" : "showDrawer"}>
          <div className="mobile_header_content p-3">
            <Link to="/">
              <h1>
                iOTAS
                <sup className="hjhjhjhfff">
                  <small>TM</small>
                </sup>
                <br />
                <small>Solutions Pvt. Ltd.</small>
              </h1>
            </Link>
            <img
              onClick={() => setShowDrawer(false)}
              src={close_icon}
              alt="close_icon"
            />
          </div>

          <div className="mobile_menu">
            <ul className="">
              <li
                onClick={() => setShowDrawer(false)}
                className="menu_item border-bottom"
              >
                <Link className="text-decoration-none text-dark" to="/">
                  Home
                </Link>
              </li>
              <div className="accordion accordion-flush" id="accordionExample">
                <AccordingMenu
                  id="collapseThree"
                  menuName="Company"
                  menuList={companyMenu}
                  setShowDrawer={setShowDrawer}
                />
                <AccordingMenu
                  id="collapseTwo"
                  menuName="Industries"
                  menuList={industriesMenu}
                  setShowDrawer={setShowDrawer}
                />
                <AccordingMenu
                  id="collapseFour"
                  menuName="Our Product"
                  menuList={productMenu}
                  setShowDrawer={setShowDrawer}
                />
                <AccordingMenu
                  id="collapseTwoc"
                  menuName="Services"
                  menuList={servicesMenu}
                  setShowDrawer={setShowDrawer}
                />
              </div>
              <li
                onClick={() => setShowDrawer(false)}
                className="menu_item border-top"
              >
                <Link
                  className="text-decoration-none text-dark"
                  to="/portfolio"
                >
                  Portfolio
                </Link>
              </li>
              <div className="accordion accordion-flush" id="accordionExample">
                <AccordingMenu
                  id="collapseFive"
                  menuName="Case Study"
                  menuList={caseStudyMenu}
                  setShowDrawer={setShowDrawer}
                />
              </div>
              <li
                onClick={() => setShowDrawer(false)}
                className="menu_item border-top"
              >
                <Link className="text-decoration-none text-dark" to="/career">
                  Career
                </Link>
              </li>
              <li
                onClick={() => setShowDrawer(false)}
                className="menu_item border-top"
              >
                <Link
                  className="text-decoration-none text-dark"
                  to="/contact-us"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
