import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./Header.css";
import {
  companyMenu,
  industriesMenu,
  productMenu,
  servicesMenu,
} from "../../data/menuData";

const Header = ({ caseStudyMenu }) => {
  const location = useLocation();
  const { id } = useParams();

  const servicePath = () => {
    return (
      location.pathname === "/custom-automation" ||
      location.pathname === "/blockchain-development" ||
      location.pathname === "/mobile-app-development" ||
      location.pathname === "/software-development" ||
      location.pathname === "/crypto-exchange" ||
      location.pathname === "/trading-exchange" ||
      location.pathname === "/resource-outsourcing" ||
      location.pathname === "/turnkey-contarct"
    );
  };

  const industriesPath = () => {
    return (
      location.pathname === "/industries" ||
      location.pathname === "/ecommerce" ||
      location.pathname === "/real-estate" ||
      location.pathname === "/municipal" ||
      location.pathname === "/water-resource" ||
      location.pathname === "/energy-monitoring" ||
      location.pathname === "/crypto-industries" ||
      location.pathname === "/trading-industries" ||
      location.pathname === "/govt-dept" ||
      location.pathname === "/tour-travel" ||
      location.pathname === "/mines"
    );
  };

  const companyPath = () => {
    return (
      location.pathname === "/about-us" ||
      location.pathname === "/partner" ||
      location.pathname === "/how-we-work" ||
      location.pathname === "/why-choose-us" ||
      location.pathname === "/our-team"
    );
  };
  const caseStudyPath = () => {
    return location.pathname === `/case-study/${id}`;
  };
  const ourproductPath = () => {
    return (
      location.pathname === "/e-office" ||
      location.pathname === "/e-PMS" ||
      location.pathname === "/trading-platform" ||
      location.pathname === "/smart-switch" ||
      location.pathname === "/ispot" ||
      location.pathname === "/solar-power-monitoring" ||
      location.pathname === "/temp-monitoring" ||
      location.pathname === "/energy-monitoring-system" ||
      location.pathname === "/building-monitoring-system" ||
      location.pathname === "/water-treatment" ||
      location.pathname === "/remote-monitoring-system" ||
      location.pathname === "/remote-water-monitoring" ||
      location.pathname === "/fire-monitoring" ||
      location.pathname === "/street-light-automation" ||
      location.pathname === "/smart-toilet" ||
      location.pathname === "/sewage-pump-house-automation" ||
      location.pathname === "/motor-health-monitoring"
    );
  };

  return (
    <div className="header">
      <div className="header_content container">
        <h1>
          iOTAS
          <sup className="hjhjdhjh">
            <small>TM</small>
          </sup>
          <br />
          <small>Solutions Pvt. Ltd.</small>
        </h1>
        <ul>
          <li
            style={{
              borderBottom: `3px solid ${
                location.pathname === "/" ? "#3e35b6" : "#fff"
              }`,
            }}
          >
            <Link
              style={{ color: `${location.pathname === "/" ? "#3e35b6" : ""}` }}
              to="/"
            >
              Home
            </Link>
          </li>
          <div className="dropdown">
            <li
              style={{
                borderBottom: `3px solid ${companyPath() ? "#3e35b6" : "#fff"}`,
              }}
            >
              <Link style={{ color: `${companyPath() ? "#3e35b6" : ""}` }}>
                Company
              </Link>
            </li>

            <div className="dropdown-content">
              {companyMenu.slice(0, 5).map((menu) => (
                <Link key={menu.id} to={menu.path}>
                  <img width={25} className="me-2" src={menu.icon} alt="img" />
                  {menu.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="dropdown">
            <li
              style={{
                borderBottom: `3px solid ${
                  industriesPath() ? "#3e35b6" : "#fff"
                }`,
              }}
            >
              <Link style={{ color: `${industriesPath() ? "#3e35b6" : ""}` }}>
                Industries
              </Link>
            </li>
            <div className="dropdown-content">
              <div className="d-flex">
                <div>
                  {industriesMenu.slice(0, 6).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
                <div>
                  {industriesMenu.slice(6, 11).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <li
              style={{
                borderBottom: `3px solid ${
                  ourproductPath() ? "#3e35b6" : "#fff"
                }`,
              }}
            >
              <Link style={{ color: `${ourproductPath() ? "#3e35b6" : ""}` }}>
                Our Product
              </Link>
            </li>
            <div className="dropdown-content">
              <div className="d-flex">
                <div>
                  {productMenu.slice(0, 6).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
                <div>
                  {productMenu.slice(6, 12).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
                <div>
                  {productMenu.slice(12, 17).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="dropdown">
            <li
              style={{
                borderBottom: `3px solid ${servicePath() ? "#3e35b6" : "#fff"}`,
              }}
            >
              <Link style={{ color: `${servicePath() ? "#3e35b6" : ""}` }}>
                Services
              </Link>
            </li>
            <div className="dropdown-content">
              <div className="d-flex">
                <div>
                  {servicesMenu.slice(0, 4).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
                <div>
                  {servicesMenu.slice(4, 8).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <li
            style={{
              borderBottom: `3px solid ${
                location.pathname === "/portfolio" ? "#3e35b6" : "#fff"
              }`,
            }}
          >
            <Link
              style={{
                color: `${location.pathname === "/portfolio" ? "#3e35b6" : ""}`,
              }}
              to="/portfolio"
            >
              Portfolio
            </Link>
          </li>

          <div className="dropdown">
            <li
              style={{
                borderBottom: `3px solid ${
                  caseStudyPath() ? "#3e35b6" : "#fff"
                }`,
              }}
            >
              <Link style={{ color: `${caseStudyPath() ? "#3e35b6" : ""}` }}>
                Case Study
              </Link>
            </li>
            <div className="dropdown-content">
              <div className="d-flex">
                <div>
                  {caseStudyMenu.slice(0, 4).map((menu) => (
                    <Link key={menu.id} to={menu.path}>
                      <img
                        width={25}
                        className="me-2"
                        src={menu.icon}
                        alt="img"
                      />
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <li
            style={{
              borderBottom: `3px solid ${
                location.pathname === "/career" ? "#3e35b6" : "#fff"
              }`,
            }}
          >
            <Link
              style={{
                color: `${location.pathname === "/career" ? "#3e35b6" : ""}`,
              }}
              to="/career"
            >
              Career
            </Link>
          </li>
          <li
            style={{
              borderBottom: `3px solid ${
                location.pathname === "/contact-us" ? "#3e35b6" : "#fff"
              }`,
            }}
          >
            <Link
              style={{
                color: `${
                  location.pathname === "/contact-us" ? "#3e35b6" : ""
                }`,
              }}
              to="/contact-us"
            >
              Contact us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
