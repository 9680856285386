import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

export const routes = {
  HomePage: {
    name: "HomePage",
    path: "/",
    element: lazy(() => import("./pages/HomePage/HomePage")),
    type: Route,
  },
  AboutPage: {
    name: "AboutPage",
    path: "/about-us",
    element: lazy(() => import("./pages/AboutPage/AboutPage")),
    type: Route,
  },
  ContactUsPage: {
    name: "ContactUsPage",
    path: "contact-us",
    element: lazy(() => import("./pages/ContactUsPage/ContactUsPage")),
    type: Route,
  },
  CareerPage: {
    name: "CareerPage",
    path: "/career",
    element: lazy(() => import("./pages/CareerPage/CareerPage")),
    type: Route,
  },
  WhyChooseUsPage: {
    name: "WhyChooseUsPage",
    path: "/why-choose-us",
    element: lazy(() => import("./pages/WhyChooseUsPage/WhyChooseUsPage")),
    type: Route,
  },
  HowWeWork: {
    name: "HowWeWork",
    path: "/how-we-work",
    element: lazy(() => import("./pages/HowWeWork/HowWeWork")),
    type: Route,
  },
  PartnerPage: {
    name: "PartnerPage",
    path: "/partner",
    element: lazy(() => import("./pages/PartnerPage/PartnerPage")),
    type: Route,
  },
  PortfolioPage: {
    name: "PortfolioPage",
    path: "/portfolio",
    element: lazy(() => import("./pages/PortfolioPage/PortfolioPage")),
    type: Route,
  },
  CustomeAutomation: {
    name: "CustomeAutomation",
    path: "/custom-automation",
    element: lazy(() => import("./pages/CustomeAutomation/CustomeAutomation")),
    type: Route,
  },
  BlockchainDevelopment: {
    name: "BlockchainDevelopment",
    path: "/blockchain-development",
    element: lazy(() =>
      import("./pages/BlockchainDevelopment/BlockchainDevelopment")
    ),
    type: Route,
  },
  MobileDevelopment: {
    name: "MobileDevelopment",
    path: "/mobile-app-development",
    element: lazy(() => import("./pages/MobileDevelopment/MobileDevelopment")),
    type: Route,
  },
  EnterPriceSoftwareDevelopment: {
    name: "EnterPriceSoftwareDevelopment",
    path: "/software-development",
    element: lazy(() =>
      import(
        "./pages/EnterPriceSoftwareDevelopment/EnterPriceSoftwareDevelopment"
      )
    ),
    type: Route,
  },
  CryptoExchange: {
    name: "CryptoExchange",
    path: "/crypto-exchange",
    element: lazy(() => import("./pages/CryptoExchange/CryptoExchange")),
    type: Route,
  },
  TradingExcahnge: {
    name: "TradingExcahnge",
    path: "/trading-exchange",
    element: lazy(() => import("./pages/TradingExcahnge/TradingExcahnge")),
    type: Route,
  },
  ResourceOutsoucing: {
    name: "ResourceOutsoucing",
    path: "/resource-outsourcing",
    element: lazy(() =>
      import("./pages/ResourceOutsoucing/ResourceOutsoucing")
    ),
    type: Route,
  },
  TurnKeyContract: {
    name: "TurnKeyContract",
    path: "/turnkey-contarct",
    element: lazy(() => import("./pages/TurnKeyContract/TurnKeyContract")),
    type: Route,
  },
  Factories: {
    name: "Factories",
    path: "/industries",
    element: lazy(() => import("./pages/Factories/Factories")),
    type: Route,
  },
  Ecommerce: {
    name: "Ecommerce",
    path: "/ecommerce",
    element: lazy(() => import("./pages/Ecommerce/Ecommerce")),
    type: Route,
  },
  RealEstate: {
    name: "RealEstate",
    path: "/real-estate",
    element: lazy(() => import("./pages/RealEstate/RealEstate")),
    type: Route,
  },
  HomeAutomation: {
    name: "HomeAutomation",
    path: "/municipal",
    element: lazy(() => import("./pages/HomeAutomation/HomeAutomation")),
    type: Route,
  },
  WaterResource: {
    name: "WaterResource",
    path: "/water-resource",
    element: lazy(() => import("./pages/WaterResource/WaterResource")),
    type: Route,
  },
  EnergyMonitoring: {
    name: "EnergyMonitoring",
    path: "/energy-monitoring",
    element: lazy(() => import("./pages/EnergyMonitoring/EnergyMonitoring")),
    type: Route,
  },
  CryptoIndustries: {
    name: "CryptoIndustries",
    path: "/crypto-industries",
    element: lazy(() => import("./pages/CryptoIndustries/CryptoIndustries")),
    type: Route,
  },
  TradingIndustries: {
    name: "TradingIndustries",
    path: "/trading-industries",
    element: lazy(() => import("./pages/TradingIndustries/TradingIndustries")),
    type: Route,
  },
  TourAndTravel: {
    name: "TourAndTravel",
    path: "/tour-travel",
    element: lazy(() => import("./pages/TourAndTravel/TourAndTravel")),
    type: Route,
  },
  GovtDept: {
    name: "GovtDept",
    path: "/govt-dept",
    element: lazy(() => import("./pages/GovtDept/GovtDept")),
    type: Route,
  },
  Eoffice: {
    name: "Eoffice",
    path: "/e-office",
    element: lazy(() => import("./pages/Eoffice/Eoffice")),
    type: Route,
  },
  Epms: {
    name: "Epms",
    path: "/e-pms",
    element: lazy(() => import("./pages/Epms/Epms")),
    type: Route,
  },
  TradingPlatform: {
    name: "TradingPlatform",
    path: "/trading-platform",
    element: lazy(() => import("./pages/TradingPlatform/TradingPlatform")),
    type: Route,
  },
  SmartSwitch: {
    name: "SmartSwitch",
    path: "/smart-switch",
    element: lazy(() => import("./pages/SmartSwitch/SmartSwitch")),
    type: Route,
  },
  SolarPowerMonitoring: {
    name: "SolarPowerMonitoring",
    path: "/solar-power-monitoring",
    element: lazy(() =>
      import("./pages/SolarPowerMonitoring/SolarPowerMonitoring")
    ),
    type: Route,
  },
  TempratureMonitoring: {
    name: "TempratureMonitoring",
    path: "/temp-monitoring",
    element: lazy(() =>
      import("./pages/TempratureMonitoring/TempratureMonitoring")
    ),
    type: Route,
  },
  EnergyMonitoringSystem: {
    name: "EnergyMonitoringSystem",
    path: "/energy-monitoring-system",
    element: lazy(() =>
      import("./pages/EnergyMonitoringSystem/EnergyMonitoringSystem")
    ),
    type: Route,
  },
  BuildingMonitoringSystem: {
    name: "BuildingMonitoringSystem",
    path: "/building-monitoring-system",
    element: lazy(() =>
      import("./pages/BuildingMonitoringSystem/BuildingMonitoringSystem")
    ),
    type: Route,
  },
  WaterTreatment: {
    name: "WaterTreatment",
    path: "/water-treatment",
    element: lazy(() => import("./pages/WaterTreatment/WaterTreatment")),
    type: Route,
  },
  RemoteMonitoring: {
    name: "RemoteMonitoring",
    path: "/remote-monitoring-system",
    element: lazy(() => import("./pages/RemoteMonitoring/RemoteMonitoring")),
    type: Route,
  },
  RemoteWaterMonitoring: {
    name: "RemoteWaterMonitoring",
    path: "/remote-water-monitoring",
    element: lazy(() =>
      import("./pages/RemoteWaterMonitoring/RemoteWaterMonitoring")
    ),
    type: Route,
  },
  ISpot: {
    name: "ISpot",
    path: "/ispot",
    element: lazy(() => import("./pages/iSpot/ISpot")),
    type: Route,
  },
  MinesIndustries: {
    name: "MinesIndustries",
    path: "/mines",
    element: lazy(() => import("./pages/MinesIndustries/MinesIndustries")),
    type: Route,
  },
  SmartToilet: {
    name: "SmartToilet",
    path: "/smart-toilet",
    element: lazy(() => import("./pages/SmartToilet/SmartToilet")),
    type: Route,
  },
  StreetLightAutomation: {
    name: "StreetLightAutomation",
    path: "/street-light-automation",
    element: lazy(() =>
      import("./pages/StreetLightAutomation/StreetLightAutomation")
    ),
    type: Route,
  },
  FireMonitoringSystem: {
    name: "FireMonitoringSystem",
    path: "/fire-monitoring",
    element: lazy(() =>
      import("./pages/FireMonitoringSystem/FireMonitoringSystem")
    ),
    type: Route,
  },
  SewagePumpHouseAutomation: {
    name: "SewagePumpHouseAutomation",
    path: "/sewage-pump-house-automation",
    element: lazy(() =>
      import("./pages/SewagePumpHouseAutomation/SewagePumpHouseAutomation")
    ),
    type: Route,
  },
  MotorHealthMonitoring: {
    name: "MotorHealthMonitoring",
    path: "/motor-health-monitoring",
    element: lazy(() =>
      import("./pages/MotorHealthMonitoring/MotorHealthMonitoring")
    ),
    type: Route,
  },
  CaseStudy: {
    name: "CaseStudy",
    path: "/case-study/:id",
    element: lazy(() => import("./pages/CaseStudy/CaseStudy")),
    type: Route,
  },
  OurTeam: {
    name: "OurTeam",
    path: "/our-team",
    element: lazy(() => import("./pages/OurTeamPage/OurTeamPage")),
    type: Route,
  },
  pageNotFound: {
    name: "Page Not Found",
    element: lazy(() => <Navigate to="/" />),
    type: Route,
  },
};

export const renderRoutes = Object.entries(routes);
