import React from 'react';
import { Link } from 'react-router-dom';

const AccordingMenu = ({ id, menuName, menuList, setShowDrawer }) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
                <button
                    className="accordion-button shadow-none collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}`}
                    aria-expanded="false"
                    aria-controls={id}
                >
                    {menuName}
                </button>
            </h2>
            <div
                id={id}
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                    <ul className="list-unstyled">
                        {menuList.map((menu) => (
                            <li onClick={() => setShowDrawer(false)} key={menu.id} className="ps-3 py-2">
                                <Link
                                    className="text-decoration-none"
                                    to={menu.path}
                                >
                                    <img
                                        width={20}
                                        className="me-2"
                                        src={menu.icon}
                                        alt="img"
                                    />
                                    {menu.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default AccordingMenu;
