import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "./ServiceChatBot.css";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#3e35b6",
  headerFontColor: "#fff",
  headerFontSize: "18px",
  botBubbleColor: "#fff",
  botFontColor: "#1e90ff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

function Review({ steps }) {
  return (
    <div style={{ width: "100%" }}>
      <h4 className="pb-3">Summary</h4>

      <table>
        <tbody>
          <tr>
            <td>Name:</td>
            <td>{steps.name.value}</td>
          </tr>
          <tr>
            <td>Email:</td>
            <td>{steps.email.value}</td>
          </tr>
          <tr>
            <td>Phone:</td>
            <td>{steps.phone.value}</td>
          </tr>
          <tr>
            <td>Service:</td>
            <td>{steps.service.value}</td>
          </tr>
          <tr>
            <td className="pe-4">Budget:</td>
            <td>{steps.budget.value}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

const FinalStep = ({ setKeyval, setShowChatBot }) => {
  return (
    <div>
      <p>
        Thanks! Your request is submitted successfully! <br /> <br /> Note your
        Ticket Id - <br /> <span>IOTAS-{uid()}</span>
        <br /> <br /> We will connect you within 24hr.
      </p>

      <button
        className="btn btn-sm btn-outline-secondary mt-3"
        onClick={() => {
          setKeyval(new Date().getTime());
          setShowChatBot(true);
        }}
      >
        Start over!
      </button>
    </div>
  );
};

const ServiceChatBot = () => {
  const chatref = useRef();
  const [keyval, setKeyval] = useState(1);
  const [showChatBot, setShowChatBot] = useState(false);

  return (
    <>
      <ThemeProvider theme={theme}>
        <ChatBot
          key={keyval}
          ref={chatref}
          floating={true}
          botAvatar="https://cdn-icons-png.flaticon.com/512/237/237131.png"
          headerTitle="i - Connect"
          placeholder="Type here..."
          floatingIcon="https://cdn-icons-png.flaticon.com/512/237/237131.png"
          // handleEnd={handleSubmitData}
          opened={showChatBot}
          steps={[
            {
              id: "1",
              message:
                "Hi! Welcome to iOTAS. How can I help you? Please enter your name to start!",
              trigger: "name",
            },
            {
              id: "name",
              user: true,
              trigger: "2",
              validator: (value) => {
                if (!value) {
                  return "Please enter valid name!";
                } else if (!/^[A-Za-z\s]*$/.test(value)) {
                  return "Please enter valid name!";
                }
                return true;
              },
            },
            {
              id: "2",
              message: "Hi {previousValue}! What is your email?",
              trigger: "email",
            },
            {
              id: "email",
              user: true,
              trigger: "3",
              validator: (value) => {
                if (
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
                ) {
                  return "Please enter valid email address!";
                }
                return true;
              },
            },
            {
              id: "3",
              message: "What is your phone number?",
              trigger: "phone",
            },
            {
              id: "phone",
              user: true,
              trigger: "4",
              validator: (value) => {
                if (!/^\d{10}$/.test(value)) {
                  return "Please enter valid phone number!";
                }
                return true;
              },
            },

            {
              id: "4",
              message: "Select the services you need?",
              trigger: "service",
            },
            {
              id: "service",
              options: [
                { value: "IOT", label: "IOT", trigger: "5" },
                { value: "Automation", label: "Automation", trigger: "5" },
                {
                  value: "Turnkey Contract",
                  label: "Turnkey Contract",
                  trigger: "5",
                },
                {
                  value: "Other Services",
                  label: "Other Services",
                  trigger: "5",
                },
              ],
            },
            {
              id: "5",
              message:
                "You have selected {previousValue}! Now select your budget type?",
              trigger: "budget",
            },
            {
              id: "budget",
              options: [
                { value: "50K to 1L", label: "50K to 1L", trigger: "6" },
                { value: "1L to 3L", label: "1L to 3L", trigger: "6" },
                { value: "3L to 5L", label: "3L to 5L", trigger: "6" },
                { value: "Above 5L", label: "Above 5L", trigger: "6" },
              ],
            },

            {
              id: "6",
              message: "Great! Check out your summary",
              trigger: "review",
            },
            {
              id: "review",
              component: <Review />,
              asMessage: true,
              trigger: "update",
            },
            {
              id: "update",
              message: "Would you like to update some field?",
              trigger: "update-question",
            },
            {
              id: "update-question",
              options: [
                { value: "yes", label: "Yes", trigger: "update-yes" },
                { value: "no", label: "No", trigger: "end-message" },
              ],
            },
            {
              id: "update-yes",
              message: "What field would you like to update?",
              trigger: "update-fields",
            },
            {
              id: "update-fields",
              options: [
                { value: "name", label: "Name", trigger: "update-name" },
                { value: "email", label: "Email", trigger: "update-email" },
                { value: "phone", label: "Phone", trigger: "update-phone" },
                {
                  value: "service",
                  label: "Service",
                  trigger: "update-service",
                },
                { value: "budget", label: "Budget", trigger: "update-budget" },
              ],
            },
            {
              id: "update-name",
              update: "name",
              trigger: "6",
            },
            {
              id: "update-email",
              update: "email",
              trigger: "6",
            },
            {
              id: "update-phone",
              update: "phone",
              trigger: "6",
            },
            {
              id: "update-service",
              update: "service",
              trigger: "6",
            },
            {
              id: "update-budget",
              update: "budget",
              trigger: "6",
            },
            {
              id: "end-message",
              component: (
                <FinalStep
                  setKeyval={setKeyval}
                  setShowChatBot={setShowChatBot}
                />
              ),
              asMessage: true,
              end: true,
            },
          ]}
        />
      </ThemeProvider>
    </>
  );
};

export default ServiceChatBot;
