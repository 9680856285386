import React from "react";

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="topbar_content container d-sm-flex justify-content-between align-items-center">
        <p>
          <i className="fa-regular fa-clock"></i> Monday - Saturday, 8AM to 10PM
        </p>
        <p>
          <i className="fa-solid fa-mobile-screen-button"></i> Call us now +91
          651-3156358
        </p>
      </div>
    </div>
  );
};

export default Topbar;
