import React from "react";
import { Link } from "react-router-dom";
import certficate1 from "../../assets/images/IOTAS_Certificates/CMMI Level 3.webp";
import certficate2 from "../../assets/images/IOTAS_Certificates/ISO 27001.webp";
import certficate3 from "../../assets/images/IOTAS_Certificates/ISO.webp";
import certficate4 from "../../assets/images/IOTAS_Certificates/MSME.webp";
import certficate5 from "../../assets/images/IOTAS_Certificates/Startup India logo.png";
import certficate6 from "../../assets/images/IOTAS_Certificates/Digital_award-removebg-preview.webp";
import certficate7 from "../../assets/images/IOTAS_Certificates/new_iso.jpg";
import certficate8 from "../../assets/images/IOTAS_Certificates/ISO_20000-1-2018-blue.png";
import "./Footer.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    id: 1,
    img: certficate1,
  },
  {
    id: 2,
    img: certficate2,
  },
  {
    id: 3,
    img: certficate3,
  },
  {
    id: 4,
    img: certficate4,
  },
  {
    id: 5,
    img: certficate5,
  },
  {
    id: 6,
    img: certficate6,
  },
  {
    id: 7,
    img: certficate7,
  },
  {
    id: 8,
    img: certficate8,
  },
];

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row gy-5">
          <div className="col-md-4">
            <div className="footer_contact">
              <h1>iOTAS Solutions Pvt. Ltd.</h1>
              <p className="pb-3">
                GS-4 & GS-10, Vinayaka <br /> Apartment, Kadru, Ranchi- <br />{" "}
                834002 (Jharkhand), India.
              </p>
              <p>
                <strong>Phone:</strong> +91 651-3156358
              </p>
              <p>
                <strong>Email:</strong> info@iotasonl.com
              </p>
            </div>

            <div className="social_link">
              <i className="fa-brands fa-twitter"></i>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/iotassolutions"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                rel="noreferrer"
                href="https://youtube.com/@iotassolutionspvt.ltd."
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/iotassolutionspvtltd/"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer_nav">
              <h1>Useful Links</h1>
              <ul>
                <li>
                  <Link to="/">
                    <i className="fa-solid fa-angle-right"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="/about-us">
                    <i className="fa-solid fa-angle-right"></i> About us
                  </Link>
                </li>
                <li>
                  <Link to="/custom-automation">
                    <i className="fa-solid fa-angle-right"></i> Services
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa-solid fa-angle-right"></i> Terms of service
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa-solid fa-angle-right"></i> Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5">
            <div className="our_newsletter">
              <h1>Our Newsletter</h1>
              <p>We join you within a second.</p>
              <form className="pb-2">
                <div className="input-group">
                  <input type="text" className="form-control" />
                  <button
                    type="submit"
                    className="btn btn-primary input-group-text"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <Slider {...settings}>
                {data.map((item) => (
                  <div className="certificate p-3">
                    <img src={item.img} alt="img" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="web_copy_right">
        <div className="container">
          <p>
            Copyright © 2022-23 | <strong>iOTAS Solutions Pvt. Ltd.</strong> |
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
