export const servicesMenu = [
  {
    id: 1,
    path: "/custom-automation",
    icon: "https://cdn-icons-png.flaticon.com/128/3745/3745205.png",
    name: "Custom Automation",
  },
  {
    id: 2,
    path: "/blockchain-development",
    icon: "https://cdn-icons-png.flaticon.com/128/6461/6461693.png",
    name: "Blockchain Development",
  },
  {
    id: 3,
    path: "/mobile-app-development",
    icon: "https://cdn-icons-png.flaticon.com/128/3137/3137807.png",
    name: "Custom Mobile App Development",
  },
  {
    id: 4,
    path: "/software-development",
    icon: "https://cdn-icons-png.flaticon.com/128/2888/2888407.png",
    name: "Enterprise Software Development",
  },
  {
    id: 5,
    path: "/crypto-exchange",
    icon: "https://cdn-icons-png.flaticon.com/128/8193/8193774.png",
    name: "Crypto Exchange Development",
  },
  {
    id: 6,
    path: "/trading-exchange",
    icon: "https://cdn-icons-png.flaticon.com/128/6405/6405566.png",
    name: "Trading Exchange Development",
  },
  {
    id: 7,
    path: "/resource-outsourcing",
    icon: "https://cdn-icons-png.flaticon.com/128/2405/2405362.png",
    name: "Resource Outsourcing",
  },
  {
    id: 8,
    path: "/turnkey-contarct",
    icon: "https://cdn-icons-png.flaticon.com/128/7257/7257970.png",
    name: "TurnKey Contracts",
  },
];

export const industriesMenu = [
  {
    id: 1,
    path: "/industries",
    icon: "https://cdn-icons-png.flaticon.com/128/4394/4394568.png",
    name: "Industries 4.O",
  },
  {
    id: 2,
    path: "/ecommerce",
    icon: "https://cdn-icons-png.flaticon.com/128/6022/6022858.png",
    name: "E-commerce",
  },
  {
    id: 3,
    path: "/real-estate",
    icon: "https://cdn-icons-png.flaticon.com/128/602/602175.png",
    name: "Real Estate",
  },
  {
    id: 4,
    path: "/municipal",
    icon: "https://cdn-icons-png.flaticon.com/128/1634/1634245.png",
    name: "Municipal",
  },
  {
    id: 5,
    path: "/water-resource",
    icon: "https://cdn-icons-png.flaticon.com/128/4749/4749343.png",
    name: "Water Resource",
  },
  {
    id: 6,
    path: "/energy-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/1015/1015322.png",
    name: "Energy Resource",
  },
  {
    id: 7,
    path: "/crypto-industries",
    icon: "https://cdn-icons-png.flaticon.com/128/8032/8032087.png",
    name: "Crypto / web 3",
  },
  {
    id: 8,
    path: "/trading-industries",
    icon: "https://cdn-icons-png.flaticon.com/128/3977/3977768.png",
    name: "Trading",
  },
  {
    id: 9,
    path: "/govt-dept",
    icon: "https://cdn-icons-png.flaticon.com/128/9346/9346018.png",
    name: "Government",
  },
  {
    id: 10,
    path: "/tour-travel",
    icon: "https://cdn-icons-png.flaticon.com/128/5333/5333597.png",
    name: "Tour & Travel",
  },
  {
    id: 11,
    path: "/mines",
    icon: "https://cdn-icons-png.flaticon.com/128/10041/10041213.png",
    name: "Mines",
  },
];

export const companyMenu = [
  {
    id: 1,
    path: "/about-us",
    icon: "https://cdn-icons-png.flaticon.com/128/189/189664.png",
    name: "About Us",
  },
  {
    id: 2,
    path: "/partner",
    icon: "https://cdn-icons-png.flaticon.com/128/5434/5434169.png",
    name: "Partners",
  },
  {
    id: 3,
    path: "/how-we-work",
    icon: "https://cdn-icons-png.flaticon.com/128/9160/9160615.png",
    name: "How We Work",
  },
  {
    id: 4,
    path: "/why-choose-us",
    icon: "https://cdn-icons-png.flaticon.com/128/189/189665.png",
    name: "Why Choose Us",
  },
  {
    id: 5,
    path: "/our-team",
    icon: "https://cdn-icons-png.flaticon.com/128/1534/1534959.png",
    name: "Our Team",
  },
];

export const productMenu = [
  {
    id: 1,
    path: "/e-office",
    icon: "https://cdn-icons-png.flaticon.com/128/3137/3137807.png",
    name: "E-office",
  },
  {
    id: 2,
    path: "/e-PMS",
    icon: "https://cdn-icons-png.flaticon.com/128/2664/2664715.png",
    name: "e-PMS",
  },
  {
    id: 3,
    path: "/trading-platform",
    icon: "https://cdn-icons-png.flaticon.com/128/3309/3309991.png",
    name: "Trading Platform",
  },
  {
    id: 4,
    path: "/smart-switch",
    icon: "https://cdn-icons-png.flaticon.com/128/3326/3326389.png",
    name: "Smart Switch",
  },
  {
    id: 5,
    path: "/ispot",
    icon: "https://cdn-icons-png.flaticon.com/128/2097/2097276.png",
    name: "iSpot",
  },
  {
    id: 6,
    path: "/solar-power-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/8816/8816989.png",
    name: "Solar Power Monitoring System",
  },
  {
    id: 7,
    path: "/temp-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/1684/1684375.png",
    name: "Temprature Monitoring System",
  },
  {
    id: 8,
    path: "/energy-monitoring-system",
    icon: "https://cdn-icons-png.flaticon.com/128/607/607303.png",
    name: "Energy Monitoring System",
  },
  {
    id: 9,
    path: "/building-monitoring-system",
    icon: "https://cdn-icons-png.flaticon.com/128/3061/3061341.png",
    name: "Building Monitoring System",
  },
  {
    id: 10,
    path: "/water-treatment",
    icon: "https://cdn-icons-png.flaticon.com/128/5755/5755728.png",
    name: "Water Treatment Process-Scada",
  },
  {
    id: 11,
    path: "/remote-monitoring-system",
    icon: "https://cdn-icons-png.flaticon.com/128/1086/1086723.png",
    name: "Remote Monitoring System",
  },
  {
    id: 12,
    path: "/remote-water-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/5202/5202426.png",
    name: "Remote Water Metering Solution",
  },
  {
    id: 13,
    path: "/fire-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/7790/7790915.png",
    name: "Fire Monitoring System",
  },
  {
    id: 14,
    path: "/street-light-automation",
    icon: "https://cdn-icons-png.flaticon.com/128/691/691282.png",
    name: "Street Light Automation",
  },
  {
    id: 15,
    path: "/smart-toilet",
    icon: "https://cdn-icons-png.flaticon.com/128/9786/9786292.png",
    name: "Smart Toilet",
  },
  {
    id: 16,
    path: "/sewage-pump-house-automation",
    icon: "https://cdn-icons-png.flaticon.com/128/2375/2375596.png",
    name: "Sewage Pump House Automation",
  },
  {
    id: 17,
    path: "/motor-health-monitoring",
    icon: "https://cdn-icons-png.flaticon.com/128/2930/2930735.png",
    name: "Motor Health Monitoring",
  },
];
