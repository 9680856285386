import React, { useState } from "react";
import "./Loader.css";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const Loader = () => {
  const [loading, setLoading] = useState(true);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className="ring-loader">
      <HashLoader
        // css={override}
        size={40}
        color={"#0b1389"}
        // loading={loading}
      />
    </div>
  );
};

export default Loader;
