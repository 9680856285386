import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MobileHeader from "../MobileHeader/MobileHeader";
import ServiceChatBot from "../ServiceChatBot/ServiceChatBot";
import Topbar from "../Topbar/Topbar";
import "./Layout.css";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import { useEffect } from "react";
import { useState } from "react";

const Layout = () => {
  const [caseStudyMenu, setCaseStudyMenu] = useState([]);

  const getCaseStudyMenu = async () => {
    try {
      const res = await fetch(
        "https://chatapi.iotasonl.com/api/web/Case_Study/Menu_List",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      const jsData = await res.json();

      let arr = [];

      for (let menu of jsData.data) {
        arr.push({
          id: menu.id,
          path: `/case-study/${menu.id}`,
          icon: "https://cdn-icons-png.flaticon.com/128/3745/3745205.png",
          name: menu.menu_name,
        });
      }
      setCaseStudyMenu(arr);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCaseStudyMenu();
  }, []);

  return (
    <div>
      <div className="main_haeder">
        <Topbar />
        <Header caseStudyMenu={caseStudyMenu} />
        <MobileHeader caseStudyMenu={caseStudyMenu} />
      </div>
      <div className="main_page_content">
        <Outlet />
      </div>
      <Footer />
      <ScrollToTopButton />
      <div className="service_chat_bot">
        <ServiceChatBot />
      </div>
    </div>
  );
};

export default Layout;
