import React from "react";
import { BrowserRouter, useLocation, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { useLayoutEffect } from "react";
import { renderRoutes } from "./routes";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route element={<Layout />}>
            {renderRoutes.map(([key, route]) => {
              return (
                <Route
                  key={key}
                  exact
                  element={<route.element />}
                  path={route.path}
                />
              );
            })}
          </Route>
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
